import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWrench,
  faSignOutAlt,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import { AuthService } from "../../services/authService";
import SiteBreadcrumb from "../SiteBreadcrumb/SiteBreadcrumb";
import logo from "../../resources/icons/worksafe_logo.png";
import "./Layout.scss";
import QRCode from "../QRCode/QRCode";

export default function Layout({ children }: { children: ReactNode }) {
  return (
      <div className="wrapper">
        <div className="top_navbar">
          <div className="top_menu">
            <NavLink to="/" className="logo">
              <div className="image">
                <img
                  src={logo}
                  alt="WorkSafe Logo"
                  width="auto"
                  height="45"
                ></img>
              </div>
              <div className="title">
                <p>WorkSafe</p>
              </div>
            </NavLink>
            <ul>
              <li>
                <NavLink to="/" title="Home" exact={true}>
                  <FontAwesomeIcon icon={faHome} />
                </NavLink>
                <div className="border-50"></div>
              </li>
              <li>
                <QRCode user_id={AuthService.getLoggedInUserId()}></QRCode>
              </li>

              {AuthService.hasSettingsAccess() ? (
                <li>
                  <NavLink to="/settings" exact={true} title="Settings">
                    <FontAwesomeIcon icon={faWrench} />
                  </NavLink>
                  <div className="border-50"></div>
                </li>
              ) : null}

              <li>
                <NavLink
                  to="/login"
                  title="Signout"
                  onClick={() => {
                    AuthService.signout();
                  }}
                  exact={true}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </NavLink>
                <div className="border-50"></div>
              </li>
            </ul>
          </div>
        </div>
        <div className="main_container">
          <div className="breadcrumb">
            <SiteBreadcrumb></SiteBreadcrumb>
          </div>
          {children}
        </div>
      </div>
  );
}
