import axios from "axios";
import { REACT_TOKEN_AUTH } from "../constants/constants";

axios.defaults.baseURL = 'https://worksafe.vulcanworks.ai/'

export const configAuthHeaders = () => {
  axios.interceptors.request.use(
    (config) => {
      if (!config.headers.Authorization) {
        const token =
          localStorage.getItem(REACT_TOKEN_AUTH) &&
          JSON.parse(localStorage.getItem(REACT_TOKEN_AUTH)).accessToken;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

export const configRefreshToken = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { config } = error;
      const originalRequest = config;
      if (
        error.response &&
        error.response.status !== 498 &&
        error.response.status !== 403 &&
        error.response.status !== 401
      ) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
      if (
        ((error.response && error.response.status === 498) ||
          (error.response && error.response.status === 403) ||
          (error.response && error.response.status === 401)) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const token =
          localStorage.getItem(REACT_TOKEN_AUTH) &&
          JSON.parse(localStorage.getItem(REACT_TOKEN_AUTH));
        const refreshToken = token && token.refreshToken;

        return axios
          .post(`/api/v2.0/auth/update-token`, {
            refreshToken,
          })
          .then((res) => {
            if (res.status === 201) {
              localStorage.setItem(
                REACT_TOKEN_AUTH,
                JSON.stringify(res.data)
              );

              originalRequest.headers.Authorization =
                "Bearer " + res.data.accessToken;
              return axios(originalRequest);
            }
          });
      } else {
        window.location.href = "/login";
        return Promise.reject(error);
      }
    }
  );
};
