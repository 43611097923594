import React, { useEffect, useState } from "react";
import "./Login.scss";
import { AuthService } from "../../services/authService";
import { useLocation, withRouter } from "react-router-dom";
import { History } from "history";
import { Tabs, Tab, Box } from "@material-ui/core";
import { faKey, faQrcode } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QrReader } from "react-qr-reader";

interface Props {
  history: History;
}

const TabPanel = React.memo(
  (props: { [x: string]: any; children: any; value: any; index: any }) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }} style={{ padding: 10, width: "100%" }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
);

const Login: React.FC<Props> = ({ history }) => {
  const location: any = useLocation();
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [invalidCreds, setInvalidCreds] = useState<boolean>(false);
  const { from } = location.state || { from: { pathname: "/" } };
  const [isWatchView, setwatchView] = useState<boolean>(false);
  const [qrData, setQrData] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  //const [loginProcessing, setLoginProcessing] = useState(false);

  useEffect(() => {
    if (from && from.pathname === "/download") {
      setwatchView(true);
    } else {
      setwatchView(false);
    }
  }, []);

  useEffect(() => {
    if (qrData) {
      AuthService.tokenAuthenticate(
        qrData,
        () => {
          history.push(from);
        },
        () => setInvalidCreds(true)
      ).finally(() => {});
    }
  }, [qrData]);

  const handleTabChange = (_event: any, newValue: number) => {
    setActiveTab(newValue);
  };
  return (
    <div className="Login">
      {isWatchView ? (
        <div className="qrLogin">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="icon position tabs example"
          >
            <Tab
              icon={<FontAwesomeIcon icon={faQrcode}></FontAwesomeIcon>}
              label="QR Login"
            ></Tab>
            <Tab
              icon={<FontAwesomeIcon icon={faKey}></FontAwesomeIcon>}
              label="Password"
            />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            {activeTab === 0 && (
              <div>
                <QrReader
                  onResult={(result, _) => {
                    if (!!result) {
                      setQrData(result.getText());
                    }
                  }}
                  constraints={{ facingMode: "environment" }}
                  containerStyle={{
                    width: "350px",
                    height: "auto",
                    transform: "rotate(180deg)",
                  }}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <div className="loginBoxInTab">
              <div className="avatar"></div>
              <div className="title">
                <p>WorkSafe</p>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  AuthService.authenticate(
                    userName,
                    password,
                    () => {
                      history.push(from);
                    },
                    () => setInvalidCreds(true)
                  );
                }}
              >
                <div className="inputField">
                  <input
                    type="text"
                    name="name"
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                    value={userName}
                    placeholder="Email"
                    autoComplete="off"
                  />
                </div>
                <div className="inputField">
                  <input
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder="Password"
                    autoComplete="off"
                  />
                </div>
                <button type="submit">Login</button>
                {invalidCreds ? (
                  <div className="error-message my-2 p-2 w-100">
                    * Username or Password is not valid
                  </div>
                ) : null}
              </form>
            </div>
          </TabPanel>
        </div>
      ) : (
        <div className="loginBox">
          <div className="avatar">
            <img
              src="worksafe_logo.png"
              alt="WorkSafe Logo"
              width="100%"
              height="auto"
            ></img>
          </div>
          <div className="title">
            <p>WorkSafe</p>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              AuthService.authenticate(
                userName,
                password,
                () => {
                  history.push(from);
                },
                () => setInvalidCreds(true)
              );
            }}
          >
            <div className="inputField">
              <input
                type="text"
                name="name"
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Email"
              />
            </div>
            <div className="inputField">
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </div>
            <button type="submit">Login</button>
            {invalidCreds ? (
              <div className="error-message my-2 p-2 w-100">
                * Username or Password is not valid
              </div>
            ) : null}
          </form>
        </div>
      )}
    </div>
  );
};
export default withRouter(Login);
