// import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
// import { OneMapSearchResultItem } from "../../services/oneMapService";
import { BuildingModel } from "../../models/BuildingModel";
import { UnitModel } from "../../models/UnitModel";
import "./LiveViewSiteSelection.scss";

export default function LiveViewSiteSelection({
  selectedSiteId,
  setSelectedSiteId,
  sites,
  selectedZoneId,
  setSelectedZoneId,
  zones,
  setLocationSearchCoordinates,
}: {
  selectedSiteId: string | number;
  setSelectedSiteId: (data: number) => void;
  sites: BuildingModel[];
  selectedZoneId: string | number;
  setSelectedZoneId: (data: number) => void;
  zones: UnitModel[];
  setLocationSearchCoordinates: ({
    lat,
    long,
  }: {
    lat: number;
    long: number;
  }) => void;
}) {
  // const [sites, setSites] = useState<BuildingModel[]>([]);
  //const [teams, setTeams] = useState<TeamModel[]>([]);
  // const [searchOptions, setSearchOptions] = useState<OneMapSearchResultItem[]>(
  //   []
  // );
  // const [searchTimeout, setSearchTimeout] = useState<any | null>();
  useEffect(() => {
    if (selectedSiteId && selectedSiteId > 0)
      setSelectedZoneId(
        zones.filter((x) => x.building_id === selectedSiteId)[0]?.unit_id ?? -1
      );
  }, [selectedSiteId]);

  return (
    <>
      <select
        className="form-element"
        onChange={(e) => setSelectedSiteId(Number.parseInt(e.target.value))}
        value={selectedSiteId}
      >
        <option key="-1" value="-1" disabled>
          Select a site
        </option>
        {sites.map((site) => (
          <option key={site.building_id} value={site.building_id}>
            {site.name}
          </option>
        ))}
      </select>

      <select
        className="form-element"
        onChange={(e) => setSelectedZoneId(Number.parseInt(e.target.value))}
        value={selectedZoneId}
      >
        <option key="-1" value="-1" disabled>
          Select a zone
        </option>
        {zones
          .filter((x) => x.building_id === selectedSiteId)
          .map((zone) => (
            <option key={zone.unit_id} value={zone.unit_id}>
              {zone.name}
            </option>
          ))}
      </select>

      {/* <Autocomplete
        options={searchOptions}
        getOptionLabel={(option) => option.ADDRESS}
        onInputChange={(_, value) => {
          if (searchTimeout !== null) {
            clearTimeout(searchTimeout);
            setSearchTimeout(null);
          }
          if (value) {
            const timeoutHandle = setTimeout(() => {
              search(value, true, true).then((results) => {
                clearTimeout(searchTimeout);
                setSearchTimeout(null);
                setSearchOptions(results.results);
              });
            }, 1000);
            setSearchTimeout(timeoutHandle);
          } else {
            setSearchOptions([]);
          }
        }}
        onChange={(_, location) => {
          const lat = Number.parseFloat(location?.LATITUDE ?? "");
          const lng = Number.parseFloat(location?.LONGITUDE ?? "");
          if (Number.isFinite(lat) && Number.isFinite(lng)) {
            setLocationSearchCoordinates({ lat: lat, long: lng });
          }
        }}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <input
              className="form-element long-element"
              placeholder="Search Location"
              type="text"
              {...params.inputProps}
            />
          </div>
        )}
      /> */}
    </>
  );
}
