import React from "react";
import { useState, useEffect } from "react";
import "./WorkShift.scss";
import axios from "axios";
import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { WorkShiftAlertModel } from "../../models/WorkShiftAlertModel";
import { dateToString } from "../../services/commonServices";
import { DateTimeCell } from "../../components/DateTimeCell/DateTimeCell";
import { BOX_COLOR, TEXT_COLOR } from "../../constants/constants";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Map, TileLayer, Marker } from "react-leaflet";
import { DurationCell } from "../../components/DurationCell/DurationCell";

const initialSort: Array<SortDescriptor> = [];

export default function WorkShift() {
  const [alerts, setAlerts] = useState<WorkShiftAlertModel[]>([]);
  const [alert, setAlert] = useState<WorkShiftAlertModel>();
  const [sort, setSort] = useState(initialSort);
  const [startDate] = useState<Date>(new Date());
  const [endDate] = useState<Date>(new Date());
  const [selectedSiteId, setSelectedSiteId] = useState<number>(-1);
  const [selectedZoneId, setSelectedZoneId] = useState<number>(-1);

  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    const search = window.location.search,
      params = new URLSearchParams(search),
      siteIdFromUrl = params.get("location"),
      zoneIdFromUrl = params.get("zoneid");

    if (siteIdFromUrl) setSelectedSiteId(parseInt(siteIdFromUrl));
    if (zoneIdFromUrl) setSelectedZoneId(parseInt(zoneIdFromUrl));
  });
  const handleOpen = (alert: WorkShiftAlertModel) => {
    setAlert(alert);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios
      .get(
        `/api/v2.0/workshift/alerts?startDate=${dateToString(
          startDate
        )}&endDate=${dateToString(
          endDate
        )}&siteId=${selectedSiteId}&zoneId=${selectedZoneId}`
      )
      .then((res) => {
        if (res && res.data && res.data.status === "success") {
          setAlerts(res && res.data && res.data.data);
        }
      })
      .catch();
  }, [selectedZoneId, selectedSiteId]);

  return (
    <div className="WorkShift">
      {/* <div>
        <h1>Work Shift Alerts</h1>
      </div> */}

      <div className="grid">
        <Grid
          data={orderBy(alerts, sort)}
          sort={sort}
          sortable={true}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
          }}
          onRowClick={(props) => {
            handleOpen(props.dataItem);
          }}
        >
          <Column className="fixed" field="first_name" title="First Name" />
          <Column className="fixed" field="last_name" title="Last Name" />
          <Column
            className="fixed"
            field="timestamp"
            title="Time"
            cell={(props: any) => <DateTimeCell {...props}></DateTimeCell>}
          />
          <Column
            className="fixed"
            field="out_of_area_duration"
            title="Duration"
            cell={(props: any) => <DurationCell {...props}></DurationCell>}
          />
        </Grid>
      </div>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}>
          Alert Location
        </DialogTitle>
        <DialogContent
          style={{
            color: TEXT_COLOR,
            backgroundColor: BOX_COLOR,
            height: "400px",
          }}
          dividers
        >
          <Map
            style={{ height: "100%" }}
            center={[1.3521, 103.8198]}
            zoom={11}
            scrollWheelZoom={true}
          >
            <TileLayer
              attribution='Map data | &copy; OpenStreetMap contributors, <a href="https://wiki.osmfoundation.org/wiki/About">OpenStreetMap</a>'
              url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {alert?.lat && alert?.long ? (
              <Marker position={[alert.lat, alert.long]}></Marker>
            ) : null}
          </Map>
        </DialogContent>
        <DialogActions
          style={{ color: TEXT_COLOR, backgroundColor: BOX_COLOR }}
        >
          <Button style={{ color: TEXT_COLOR }} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
