import React from "react";
import { NavLink } from "react-router-dom";
import "../Home/Home.scss";

export default function Home() {
  return (
    <div className="Home">
      <div className="welcome container">
        <div className="text">
          <h2>Welcome to WorkSafe</h2>
          <p>
            Vulcan WorkSafe keeps your workplace safe by using Wearable Vision &
            AI Technology to provide real-time detection and alerts of any
            safety related incidents.
          </p>
        </div>
        <div className="icon">
          <img src="worksafe_logo_text.png" alt="WorkSafe Logo" />
        </div>
      </div>

      <div className="feature_title">
        <h2>Features</h2>
      </div>

      <div className="features ">
        <div className="feature">
          <NavLink to="/live" className="icon">
            <div className="circle">
              <img src="live-icon.svg" alt="ops icon"></img>
            </div>
          </NavLink>
          <NavLink to="/live" className="title">
            <h4>Live</h4>
          </NavLink>
          <div className="desc">
            <p>Your Staff's current location, tasks and fatigue levels</p>
          </div>
        </div>
        <div className="feature">
          <NavLink to="/daily-ops" className="icon">
            <div className="circle">
              <img src="ops-icon.svg" alt="ops icon"></img>
            </div>
          </NavLink>
          <NavLink to="/daily-ops" className="title">
            <h4>Operations</h4>
          </NavLink>
          <div className="desc">
            <p>Daily Reports on Activities Completed and Productivity Levels</p>
          </div>
        </div>
        <div className="feature">
          <NavLink to="/daily-dashboard" className="icon">
            <div className="circle">
              <img src="safety-icon.svg" alt="ops icon"></img>
            </div>
          </NavLink>
          <NavLink to="/daily-dashboard" className="title">
            <h4>Safety</h4>
          </NavLink>
          <div className="desc">
            <p>Health and Safety Incident Reports</p>
          </div>
        </div>
      </div>
    </div>
  );
}
