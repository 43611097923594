import React, { useEffect, useState } from "react";

import "./BuildingForm.scss";

import { MenuItem, Select, TextField } from "@material-ui/core";
import { TeamModel } from "../../../models/TeamModel";

interface Props {
  teams: TeamModel[];
  buildingName: string;
  buildingDescription: string;
  teamId: number;
  zipcode: string;
  latitude: string;
  longitude: string;
  setBuildingName: (
    buildingName: string | ((prevState: string) => string)
  ) => void;

  setBuildingDescription: (
    buildingDesc: string | ((prevState: string) => string)
  ) => void;

  setTeamId: (team_id: number | ((prevState: number) => number)) => void;

  setZipcode: (zipcode: string | ((prevState: string) => string)) => void;

  setLatitude: (latitude: string | ((prevState: string) => string)) => void;

  setLongitude: (longitude: string | ((prevState: string) => string)) => void;
}

export default function BuildingForm(props: Props) {
  const [buildingName, setBuildingName] = useState<string>(props.buildingName);
  const [buildingDesc, setBuildingDesc] = useState<string>(
    props.buildingDescription
  );
  const [zipCode, setZipCode] = useState<string>(props.zipcode);
  const [latitude, setLatitude] = useState<string>(props.latitude);
  const [longitude, setLongitude] = useState<string>(props.longitude);

  useEffect(() => {
    props.setBuildingName(buildingName);
    // eslint-disable-next-line
  }, [buildingName]);

  useEffect(() => {
    props.setBuildingDescription(buildingDesc);
    // eslint-disable-next-line
  }, [buildingDesc]);

  useEffect(() => {
    props.setZipcode(zipCode);
    // eslint-disable-next-line
  }, [zipCode]);

  useEffect(() => {
      props.setLatitude(latitude);
      // eslint-disable-next-line
    }, [latitude]);

  useEffect(() => {
      props.setLongitude(longitude);
      // eslint-disable-next-line
    }, [longitude]);

  const handleTeamChange = (event: { target: { value: any } }) => {
    props.setTeamId(event.target.value);
  };
  return (
    <div className="BuildingForm">
      <div className="name">
        <h4 className="required">Location Name</h4>
        <TextField
          fullWidth
          placeholder="Location Name"
          size="medium"
          value={buildingName}
          onChange={(e) => {
            setBuildingName(e.target.value);
          }}
        />
      </div>

      <div className="desc">
        <h4>Description</h4>
        <TextField
          fullWidth
          placeholder="Description"
          size="medium"
          value={buildingDesc}
          onChange={(e) => {
            setBuildingDesc(e.target.value);
          }}
        />
      </div>

      <div className="desc">
        <h4>Zipcode</h4>
        <TextField
          fullWidth
          placeholder="Zipcode"
          size="medium"
          value={zipCode}
          onChange={(e) => {
            setZipCode(e.target.value);
          }}
        />
      </div>

      <div className="desc">
        <h4>Latitude</h4>
        <TextField
          fullWidth
          placeholder="Latitude"
          size="medium"
          value={latitude}
          onChange={(e) => {
            setLatitude(e.target.value);
          }}
        />
      </div>

      <div className="desc">
        <h4>Longitude</h4>
        <TextField
          fullWidth
          placeholder="Longitude"
          size="medium"
          value={longitude}
          onChange={(e) => {
            setLongitude(e.target.value);
          }}
        />
      </div>

      <div className="team">
        <h4 className="required">Select Team</h4>
        <Select fullWidth value={props.teamId} onChange={handleTeamChange}>
          {props.teams.map((team) => (
            <MenuItem key={team.team_id} value={team.team_id}>
              {team.team_name}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}
